<template>
	<!-- 建筑服务 -->
	<div id="construct">
		<!-- 建筑服务列表 -->
		<div class="serviceConstruct">
			<div class="serviceMain">
				<p class="title">建筑<span>服务</span></p>
				<div class="detailService">
					<div
						class="detail"
						v-for="item in constructList"
						:key="item.id"
						@mouseenter="enter(1)"
						@mouseleave="leave"
						@click="btnClick(item)"
					>
						<div style="overflow: hidden">
							<img class="detailImg" :src="item.categoryUrl" alt="" />
						</div>
						<div class="text">
							<p>{{ item.categoryName }}</p>
							<div>了解更多>></div>
						</div>
					</div>
					<el-dialog
						:title="title"
						:visible.sync="dialogVisible"
						width="30%"
						:before-close="handleClose"
					>
						<div class="junior">
							<div
								:class="
									id == items.id ? 'classify gree pointer' : 'classify pointer'
								"
								:style="{
									marginRight: `${
										Math.round((index + 1) / 3) == (index + 1) / 3
											? '0px'
											: '14px'
									}`,
								}"
								v-for="(items, index) in constructData"
								:key="items.id"
								@click="btnText(items)"
							>
								{{ items.categoryName }}
							</div>
						</div>
						<div class="classify_text">
							<p class="priceMoney">价格：{{ price }}/㎡</p>
							<p class="priceRemark" v-html="constructText"></p>
						</div>
					</el-dialog>
				</div>
			</div>
		</div>
		<!-- 服务案例 -->
		<div class="caseBody">
			<serviceCase></serviceCase>
		</div>
	</div>
</template>

<script>
import footerService from '../../components/footerService.vue'
import serviceCase from './components/serviceCase.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
	name: 'GejMallPcIndex',
	components: {
		footerService,
		Swiper,
		SwiperSlide,
		serviceCase,
	},

	data() {
		return {
			// 轮播图
			imgCarousel: [],
			// 建筑服务-弹框
			dialogVisible: false,
			title: '',
			id: '1',
			// 服务列表
			serviceList: [],
			// 建筑服务
			constructList: [],
			// 弹框
			constructData: [],
			constructText: '',
			price: '',
		}
	},

	mounted() {
		this.init()
	},

	methods: {
		init() {
			this.api.queryDecorativeCategory().then((res) => {
				if (res.data) {
					let resData = JSON.parse(JSON.stringify(res.data)).filter((item) => {
						if (item.parentId == 0) {
							return item
						}
					})

					this.serviceList = res.data
					this.constructList = resData
				}
			})
			this.api.treeSelect().then((res) => {
				if (res.code == 0) {
					// this.constructList = res.data
				}
			})
		},
		// 鼠标划入划出事件
		enter(num) {
			this.isShow = num
		},
		leave() {
			this.isShow = ''
		},
		// 了解更多
		btnClick(data) {
			let parentId = data.id
			let serviceData = JSON.parse(JSON.stringify(this.serviceList))
			const constructData = serviceData.filter((item) => {
				return item.parentId == parentId
			})

			this.constructData = constructData
			this.dialogVisible = true
			this.title = data.label
			this.id = constructData[0].id
			this.constructText = constructData[0].remark
			this.price = constructData[0].price
		},
		// 了解更多子级
		btnText(data) {
			this.price = data.price
			this.constructText = data.remark
			this.id = data.id
		},
		// 弹框关闭
		handleClose() {
			this.dialogVisible = false
			this.title = ''
			this.constructText = ''
			this.price = ''
			this.id = ''
		},
	},
}
</script>

<style lang="scss" scoped>
#construct {
	.serviceConstruct {
		width: 100%;
		padding-bottom: 88px;
		::v-deep.el-dialog__wrapper {
			.el-dialog {
				.el-dialog__header {
					padding-bottom: 0;
					padding-left: 40px;
					padding-right: 40px;
					.el-dialog__title {
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #03825a;
						display: inline-block;
						width: 100%;
						text-align: center;
					}
				}
				.junior {
					display: flex;
					flex-wrap: wrap;
					.classify {
						width: 167px;
						height: 46px;
						background: #777777;
						text-align: center;
						line-height: 46px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						margin-top: 20px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						padding-left: 5px;
						padding-right: 5px;
					}
					.gree {
						background: #03825a;
					}
					.margs {
						margin-right: 9px;
					}
				}
				.classify_text {
					padding-bottom: 30px;
					.priceMoney {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						margin-top: 46px;
						padding-bottom: 5px;
					}
				}
			}
		}
		.serviceMain {
			width: 1200px;
			margin: 0 auto;
			.title {
				text-align: left;
				font-size: 40px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				padding-top: 60px;
			}
			.title > span {
				color: #03825a;
			}
			.detailService {
				width: 1200px;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.detail {
					width: 578px;
					height: 358px;
					cursor: pointer;
					margin-top: 40px;
					box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
					overflow: hidden;
					.detailImg {
						width: 578px;
						height: 283px;
						transition: all 0.8s ease;
					}
					.text {
						height: 75px;
						background: white;
						display: flex;
						justify-content: space-between;
						align-items: center;
						transition: all 0.8s ease;
						P {
							font-size: 24px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #03825a;
							padding-left: 18px;
							transition: all 0.8s ease;
						}
						div {
							width: 120px;
							height: 38px;
							line-height: 38px;
							text-align: center;
							// background: #FFFFFF;
							background: #03825a;
							margin-right: 20px;
							transition: all 0.8s ease;

							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							// color: #03825A;
							color: #ffffff;
						}
					}
				}
				.detail:hover {
					.detailImg {
						transform: scale(1.1);
					}
					.text {
						background: #03825a;

						p {
							color: white;
						}
						div {
							background: white;

							color: #03825a;
						}
					}
				}
			}
		}
	}
	.caseBody {
		background: white !important;
		width: 100%;
		.case {
			width: 1200px;
			height: 617px;
			overflow: hidden;
			margin: 0 auto;
			background: white;
			::v-deep.text {
				background-color: #f4f4f4;
				width: 100%;
			}
		}
	}
}
</style>
<style scoped>
.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
	background-color: #d3dce6;
}
.swiper-button-prev {
	left: 0px;
	width: 24px;
	height: 38px;
	background: rgba(51, 51, 51, 0.4);
	border-radius: 0px 4px 4px 0px;
}
.swiper-button-next {
	right: 0px;
	width: 24px;
	height: 38px;
	background: rgba(51, 51, 51, 0.4);
	border-radius: 4px 0px 0px 4px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
	content: none;
}
.icon_left_right {
	width: 11px;
	height: 21px;
}
</style>